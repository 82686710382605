import React from 'react'
import './totlevalue.css'

const Totlevalue = () => {
  return (
    <div className='totle_description_container'>
      <div className='item_description'>
     <h2>$510.9M</h2>
     <p>Total Liquidity Raised</p>
      </div>
      <div className='item_description'>
      <h2>15862</h2>
     <p>Total Project</p>
      </div>
      <div className='item_description'>
      <h2> 1.7M</h2>
     <p>Total Participants</p>
      </div>
      <div className='item_description'>
      <h2>$263.3M</h2>
     <p>Total Values Locked</p>
      </div>
    </div>
  )
}

export default Totlevalue
