import React from 'react'
import Token from '../Token/Token'
const Liquidity = () => {
  return (
    <>
      <Token/>
    </>
  )
}

export default Liquidity
