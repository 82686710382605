import React from 'react'
// import './Affiliate.css'

const Affiliate = () => {
  return (
    <>
        {/* <div className='affiliate'>
        <p>2%</p>
        <p>Affiliate</p>
    </div> */}
    </>
    
  )
}

export default Affiliate