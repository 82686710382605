import React from 'react'
// import TokenSalesCard from './TokenSalesCard/TokenSalesCard'
// import TokenSales from '../../TokenSales.json'

const TokenSalesMainLayout = () => {
  return (
    <div>
      <div className='launchpad_section'>
      <div className='container'>
        <div className='launchpad_main'>
          <div className='launchpad_protocol_text'>
            <h1>A Suite of Tools for Token Sales.</h1>
            <p>A suite of tools were built to help you create your own tokens and launchpads in a fast, simple and cheap way, with no prior code knowledge required and 100% decentralized!</p>
          </div>
        </div>
      </div>
    </div>
        {/* <TokenSalesCard TokenSales={TokenSales}/> */}
    </div>
  )
}

export default TokenSalesMainLayout