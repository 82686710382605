import React from 'react'
import BodyContent from '../Bodycontent/BodyContent'
import LaunchpadProtocol from './LaunchpadProtocol'

const MainLayoutLaunchpad = () => {
  return (
    <>
    <LaunchpadProtocol/>
    </>
  )
}

export default MainLayoutLaunchpad